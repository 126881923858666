import React from "react"
import styled from "@emotion/styled"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"

const ContactForm = styled.form`
  label {
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    display: block;
    padding: 1rem 0;
  }
  input {
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    width: 100%;
    padding: 1rem 0;
    border-radius: 5px;
  }
  textarea {
    display: block;
    width: 100%;
    min-height: 500px;
    padding: 1rem 0.25rem;
    border-radius: 5px;
  }
  input[type="submit"] {
    background-color: lightgreen;
  }
`

export default ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title="Contact" />
    <ContactForm
      name="contact"
      action={`https://www.wienkit.nl${locale.prefix}/thanks`}
      method="POST"
      data-netlify="true"
      netlify-honeypot="bot-field"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <div>
        <label htmlFor="name">Your name</label>
        <input type="text" name="name" placeholder="Enter your name" />
      </div>
      <div>
        <label htmlFor="email">Your email address</label>
        <input
          type="text"
          name="email"
          placeholder="Enter your email address"
        />
      </div>
      <div>
        <label htmlFor="content">Your email address</label>
        <textarea name="content" placeholder="Enter your message" />
      </div>
      <div>
        <input type="submit" value="Submit" />
      </div>
    </ContactForm>
  </Layout>
)
