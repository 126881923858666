import { StaticQuery } from "gatsby"
import React from "react"
import styled from "@emotion/styled"
import { Container } from "./Layout"

const SiteTitle = styled.span`
  color: #fff;
  font-size: 2.25rem;
  line-height: 1.1;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
`

const Header = styled.header`
  background: #4d89ff;
  padding-top: 1.45rem;
`

export default () => (
  <StaticQuery
    query={QUERY}
    render={({ prismicFrontpage: { data } }) => (
      <Header>
        <Container>
          <SiteTitle>{data.title.text}</SiteTitle>
        </Container>
      </Header>
    )}
  />
)

const QUERY = graphql`
  query Header {
    prismicFrontpage {
      data {
        title {
          text
        }
      }
    }
  }
`
