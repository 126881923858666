import React from "react"
import styled from "@emotion/styled"
import { Link as UnstyledLink, StaticQuery, graphql } from "gatsby"
import { LocaleContext, Container } from "./Layout"

const Navigation = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0px 1.0875rem;
`

const LanguageSwitcher = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0px 1.0875rem;
`

const NavItem = styled.li`
  display: inline;
`

const Link = styled(UnstyledLink)`
  display: inline-block;
  padding: 8px;
  color: #000;
  text-decoration: none;
  border-bottom: ${props => (props.active ? "2px solid #000" : null)};
  &.is-active {
    border-bottom: 2px solid #000;
  }
`

const Navbar = ({ currentLocale, locales }) => (
  <Container
    as="nav"
    css={{
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    <Navigation>
      <NavItem>
        <Link activeClassName="is-active" to={currentLocale.prefix}>
          Home
        </Link>
      </NavItem>
      <NavItem>
        <Link activeClassName="is-active" to={`${currentLocale.prefix}/blogs`}>
          Blog
        </Link>
      </NavItem>
      <NavItem>
        <Link
          activeClassName="is-active"
          to={`${currentLocale.prefix}/contact`}
        >
          Contact
        </Link>
      </NavItem>
    </Navigation>
    <LanguageSwitcher>
      {locales.map(locale => (
        <NavItem>
          <Link
            active={locale.prefix === currentLocale.prefix}
            to={locale.prefix}
          >
            {locale.name}
          </Link>
        </NavItem>
      ))}
    </LanguageSwitcher>
  </Container>
)

export default () => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            locales {
              name
              prefix
              langcode
            }
          }
        }
      }
    `}
    render={data => (
      <LocaleContext.Consumer>
        {locale => (
          <Navbar
            currentLocale={locale}
            locales={data.site.siteMetadata.locales}
          />
        )}
      </LocaleContext.Consumer>
    )}
  />
)
