import { StaticQuery } from "gatsby"
import React from "react"
import styled from "@emotion/styled"
import { Container } from "./Layout"

const Copyright = styled.div`
  margin-right: 1.45rem;
  display: inline;
`

const Footer = styled.footer`
  color: #fff;
  background: #4d89ff;
  padding-top: 1.45rem;
  a {
    color: #fff;
  }
`

export default () => (
  <StaticQuery
    query={QUERY}
    render={({ prismicFrontpage: { data } }) => (
      <Footer>
        <Container>
          <Copyright>© {new Date().getFullYear()}, Wienk IT</Copyright>
          <a href={data.terms_conditions.url}>
            Terms & conditions | Algemene voorwaarden
          </a>
        </Container>
      </Footer>
    )}
  />
)

const QUERY = graphql`
  query Footer {
    prismicFrontpage {
      data {
        terms_conditions {
          url
        }
      }
    }
  }
`
