import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

import Header from "./Header"
import Navigation from "./Navigation"
import Footer from "./Footer"
import "./layout.css"

export const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0px 1.0875rem 1.45rem;
`

export const Card = styled.div`
  padding: ${props => (props.padded ? "1rem" : "0")};
  background-color: #fff;
  box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
  line-height: 1.4;
  border-radius: 5px;
  margin-bottom: 1.25rem;
  overflow: hidden;
`

const Main = styled.main`
  background-color: #f1f1f1;
  padding-top: 1.25rem;
`

export const LocaleContext = React.createContext({})

const Layout = ({ children, locale }) => (
  <LocaleContext.Provider value={locale}>
    <Header />
    <Navigation />
    <Main>
      <Container>{children}</Container>
    </Main>
    <Footer />
  </LocaleContext.Provider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
